import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { userRoutes, authRoutes } from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import VerticalLayout from "./layout"
import NonAuthLayout from "./components/layout/NonAuthLayout"
import "./assets/scss/theme.scss"
import { verifyToken } from './utils/verifyToken';
import { Analytics } from '@vercel/analytics/react'; // FOR ANALYTICS WITH VERCEL

import AOS from "aos"
import "aos/dist/aos.css"

import useFetch from "hooks/useFetch";
import { loginUser } from "Redux/Slices/userSlice";
import { useStore1Dispatch, useStore1Selector } from 'index'

const App = props => {

  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const token = userDet?.token;
  const history = useHistory()
  const { error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/users/logout`, token);

  useEffect(() => {
    if (error?.status === 401) {
      dispatch(Login(""));
      history.push('/login');
    }
  }, [error, history, dispatch, token]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
    AOS.refresh()
  }, [])

  useEffect(() => {
    verifyToken(history);
  }, []);


  return (
    <div>
      <React.Fragment>
        <Router>
          <Switch>

            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}

          </Switch>
        </Router>
      </React.Fragment>
      <Analytics />
    </div>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
