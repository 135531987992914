import React from 'react';
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import html2pdf from "html2pdf.js";

const ModalComp = ({ open, onClose, ModalTitle, cancel, deleteBtn, Component }) => {

    if (!open) return null;

    const handleDownload = () => {
        const element = document.getElementById("loanContentToDownload");

        const opt = {
            margin: 0.5,
            filename: "LoanDetails.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: {
                scale: 2,
            },
            jsPDF: {
                unit: "in",
                format: "letter",
                orientation: "portrait",
            },
        };

        html2pdf()
            .from(element)
            .set(opt)
            .save();
    };

    return (
        <Modal show={open} onHide={onClose} size="xl" >
            <Modal.Header className="d-flex justify-content-center">
            </Modal.Header>

            <Modal.Body>
                {Component}
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-end p-2">
                <Button className="btn btn-primary text-white" onClick={handleDownload}> {"Download PDF"} </Button>
                <Button className="btn btn-danger text-white" onClick={onClose}> {cancel} </Button>
                {
                    !deleteBtn ? null : (
                        <Button className="btn btn-danger text-white" onClick={onClose}> {deleteBtn} </Button>
                    )
                }
            </Modal.Footer>
        </Modal>
    );
};

export default ModalComp;