import LoanFeeBreakdown from "components/common/LoanFeeBreakdown";
import LoadingAnimation from "components/helper/Loading-animation";
import useFetch from "hooks/useFetch";
import { useStore1Selector } from "index";
import React from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { loginUser } from "Redux/Slices/userSlice";
import { formatDate } from "../../utils/dateFormat";


function ViewLoan({ loan_Id }) {
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;

    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const { data, loading } = useFetch(
        `${process.env.REACT_APP_BACKEND_URL}/loans/${loan_Id}`,
        token
    );

    if (loading) return <LoadingAnimation />;

    const totalExpenses =
        Number(data?.bondRent) +
        Number(data?.carInstallments) +
        Number(data?.dependents) +
        Number(data?.loanCredit) +
        Number(data?.otherExpenses);

    const disposalIncome = (Number(data?.monthlyIncomeGross) - totalExpenses)


    return (
        <div id="loanContentToDownload">
            <h1 className="p-2 py-4 mb-4 text-center"> LOAN APPLICATION </h1>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={4}>
                            <label className="form-label">Amount *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Amount"
                                value={`R ${Number(data?.amount).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>

                        <Col md={4}>
                            <label className="form-label">Interest *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Interest"
                                value={`R ${Number(data?.totalAmount).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Amount + Interest *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Amount & Interest"
                                value={`R ${Number(data?.amountPlusInterest).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <label className="form-label">Payment Date *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Payment Date"
                                value={data?.paymentDate?.split("T")[0] || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Created Date *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Created Date"
                                value={data?.createdAt?.split("T")[0] || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Updated Date *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Updated Date"
                                value={data?.updatedAt?.split("T")[0] || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Duration *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Duration"
                                value={`${data?.duration} Days` || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Status *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Status"
                                value={data?.status || "N/A"}
                                readOnly
                            />
                        </Col>

                        <Col md={4}>
                            <label className="form-label">Total Expenses *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Total Expenses"
                                value={`R ${totalExpenses.toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                    </Row>
                </Col>

                <Col md={6}>
                    <Row>
                        <Col md={4}>
                            <label className="form-label">Income *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Income "
                                value={`R ${Number(data?.monthlyIncomeGross).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Credit *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Credit"
                                value={`R ${Number(data?.loanCredit).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>

                        <Col md={4}>
                            <label className="form-label">Bond Rent *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Bond Rent"
                                value={`R ${Number(data?.bondRent).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <label className="form-label">Car Installment *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Car Installment"
                                value={`R ${Number(data?.carInstallments).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>
                        <Col md={4}>
                            <label className="form-label">Dependents *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Dependents"
                                value={`R ${Number(data?.dependents).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>

                        <Col md={4}>
                            <label className="form-label">Other Expenses *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Other Expenses"
                                value={`R ${Number(data?.otherExpenses).toFixed(2)}` || "N/A"}
                                readOnly
                            />
                        </Col>


                    </Row>

                    <Row>
                        <Col md={4}>
                            <label className="form-label">Disposal Income *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Car Installment"
                                value={`R ${disposalIncome}` || "N/A"}
                                readOnly
                            />
                        </Col>
                    </Row>
                </Col>

                <Row className="mt-4">
                    {data?.gracePeriodPercentage ? (
                        <Col md={4}>
                            <label className="form-label">Grace Period Percentage *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Grace Period Percentage"
                                value={data?.gracePeriodPercentage || "N/A"}
                                readOnly
                            />
                        </Col>
                    ) : null}

                    {data?.gracePeriodPaymentDate ? (
                        <Col md={4}>
                            <label className="form-label">Grace Period Payment Date *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Grace Period Payment Date"
                                value={formatDate(data?.gracePeriodPaymentDate) || "N/A"}
                                readOnly
                            />
                        </Col>
                    ) : null}

                    {data?.gracePeriodAmount ? (
                        <Col md={4}>
                            <label className="form-label">Grace Period Amount *</label>
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Grace Period Amount"
                                value={data?.gracePeriodAmount || "N/A"}
                                readOnly
                            />
                        </Col>
                    ) : null}
                </Row>

                <Col md={12}>
                    <label className="form-label" style={{ marginTop: "30px" }}>
                        Declined Reason :
                    </label>
                    <p> {data?.loanDeclineReason} </p>
                </Col>

                <Row className=" mt-5">
                    <Col md={6}>
                        <div style={{ textAlign: "left" }}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}>Signature:</p>
                            {data.loanSignature && (
                                <div
                                    style={{
                                        display: "inline-block",
                                        border: "1px solid #ddd",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                    }}
                                >
                                    <img
                                        src={data.loanSignature}
                                        alt="Signature"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mt-1">
                            <p>
                                I hereby allow ritikefinance to access my credit Bureau
                                information from XDS, to used solely for the following purposes.{" "}
                            </p>
                            <p>
                                1.1 Acredit assessment in respect of a consumer as required by
                                section 81(2) of the act.
                            </p>
                            <p>
                                {" "}
                                1.2 An affordability assessment in respect of a consumer as
                                required by section 81 of the act.
                            </p>
                            <p>
                                2 I consent to XDS releasing a copy of my credit to ritikefinance
                                having sight of the content of my credit report for the above
                                purpose.{" "}
                            </p>
                        </div>
                    </Col>
                </Row>
                {/* _____________________UN COMMENT OUT THIS PART HERE AFTER NCR_____________________ */}
                {/* <LoanFeeBreakdown /> */}

                <div className="mt-2">
                    <Button
                        color="primary"
                        onClick={toggle}
                        style={{ marginBottom: "1rem" }}
                    >
                        View Bank Statements & Payslip
                    </Button>
                    <Collapse isOpen={isOpen}>
                        {data?.bankStatement_and_payslip &&
                            data.bankStatement_and_payslip.length > 0 ? (
                            data.bankStatement_and_payslip.map((PDF, i) => (
                                <div key={i} className="file-container">
                                    <embed
                                        src={PDF}
                                        type="application/pdf"
                                        width="100%"
                                        height="400px"
                                    />
                                </div>
                            ))
                        ) : (
                            <p style={{ textAlign: "center", marginTop: "1rem" }}>
                                No bank statements or payslips available.
                            </p>
                        )}
                    </Collapse>
                </div>
            </Row>
        </div>
    );
}

export default ViewLoan;
