import LoanFeeBreakdown from "components/common/LoanFeeBreakdown";
import { useStore1Selector } from "index";
import React from "react";
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import LoadingAnimation from "../../components/helper/Loading-animation";
import { ServerError } from "../../constant/NotifyMessage";
import { LoanRequestRoute } from "../../constant/RouteName";
import { LoanRequestPage } from "../../constant/SCO_Name";
import useFetch from '../../hooks/useFetch';
import { loginUser } from "../../Redux/Slices/userSlice";
import { formatDate } from "../../utils/dateFormat";
import html2pdf from "html2pdf.js";



const MandateDetails = () => {
    const { id } = useParams()

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates/${id}`, token);
    const TotalToPay = parseInt(data?.loan?.totalAmount) + parseInt(data?.loan?.amount)

    if (loading) return <LoadingAnimation />

    if (error) return <ErrorPage message={ServerError} />

    const handleDownload = () => {
        const element = document.getElementById("mandateContentToDownload");

        const opt = {
            margin: 0.5,
            filename: "Mandate-Details.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: {
                scale: 2,
            },
            jsPDF: {
                unit: "in",
                format: "letter",
                orientation: "portrait",
            },
        };

        html2pdf()
            .from(element)
            .set(opt)
            .save();
    };


    return (
        <React.Fragment>
            <div className="page-content px-5" >
                <Breadcrumb default={LoanRequestRoute} defaultName="Mondate form" title={"details"} />
                <MetaTag title_sco={LoanRequestPage} />
                <Container fluid>
                    <div id="mandateContentToDownload">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h1 className="text-center">MANDATE</h1>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={4}>
                                        <label className="form-label">Full Name *</label>
                                        <input type="text" className="form-control" aria-label="Full Name" value={`${data?.user?.firstName || 'N/A'} ${data?.user?.lastName || 'N/A'}`} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">Phone Number *</label>
                                        <input type="text" className="form-control" aria-label="Phone Number" value={data?.user?.phoneNumber || 'N/A'} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">ID Number *</label>
                                        <input type="text" className="form-control" aria-label="ID Number" value={data?.loan?.user?.idNumber || 'N/A'} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">Email *</label>
                                        <input type="email" className="form-control" aria-label="Email" value={data?.user?.email || 'N/A'} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">Account Number *</label>
                                        <input type="text" className="form-control" aria-label="Account Number" value={data?.user?.accountNumber || 'N/A'} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">Bank Name *</label>
                                        <input type="text" className="form-control" aria-label="Bank Name" value={data?.user?.bankName || 'N/A'} readOnly />
                                    </Col>

                                    <Col md={4}>
                                        <label className="form-label">Payment Date *</label>
                                        <input type="text" className="form-control" aria-label="Payment Date" value={formatDate(data?.loan?.paymentDate)} readOnly />
                                    </Col>

                                    {/* <Col md={4}>
                                    <label className="form-label">Loan Amount *</label>
                                    <input type="text" className="form-control" aria-label="Loan Amount" value={`R ${data?.loan?.amount || 'N/A'}`} readOnly />
                                </Col> */}

                                    <Col md={4}>
                                        <label className="form-label">Amount to Pay *</label>
                                        <input type="text" className="form-control" aria-label="Amount to Pay" value={`R ${TotalToPay || 'N/A'}`} readOnly />
                                    </Col>

                                    {
                                        data?.gracePeriodAmount ?
                                            <Col md={4}>
                                                <label className="form-label">Grace Period Amount *</label>
                                                <input type="text" className="form-control" aria-label="Payment Date" value={data?.gracePeriodAmount} readOnly />
                                            </Col> : null
                                    }

                                    {
                                        data?.gracePeriodPercentage ?
                                            <Col md={4}>
                                                <label className="form-label">Grace Period Percentage *</label>
                                                <input type="text" className="form-control" aria-label="Payment Date" value={data?.gracePeriodPercentage} readOnly />
                                            </Col> : null
                                    }

                                    {
                                        data?.gracePeriodPaymentDate ?
                                            <Col md={4}>
                                                <label className="form-label">Grace Period Payment Date *</label>
                                                <input type="text" className="form-control" aria-label="Payment Date" value={formatDate(data?.gracePeriodPaymentDate)} readOnly />
                                            </Col> : null
                                    }
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <div className="loan-fee-breakdown p-4 bg-light border rounded mt-4">
                                            <div className="fee-item mb-3">
                                                <p>
                                                    <strong>I hereby instruct and grant permission to RITIKE FINANCE (PTY) LTD, using its service provider
                                                        (Nupay) to debit against my above mentioned account (or any other bank, or branch office of a bank
                                                        to which the account may be transferred) in respect of all the amounts which are due and payable by
                                                        me to RITIKE FINANCE in terms of the loan agreement which I entered. </strong>
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mt-4">
                                    <p>If however, the date of the payment instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the following business day:</p>
                                    <h5 className="text-primary">{data?.debitedAgree ? "Yes" : "No"}</h5>
                                </div>

                                <div className="mt-4">
                                    <p>The date of the instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the business day prior to the non-processing day:</p>
                                    <h5 className="text-primary">{data?.debitedAgree2 ? "Yes" : "No"}</h5>
                                </div>

                                <div className="mt-4">
                                    <p>To allow for tracking of dates to match with flow of Credit at no additional cost to myself:</p>
                                    <h5 className="text-primary">{data?.trackingOfDate ? "Yes" : "No"}</h5>
                                </div>

                                <div className="mt-4">
                                    <p>I authorize the originator to make use of the tracking facility as provided for in the EDO system at no additional cost to myself:</p>
                                    <h5 className="text-primary">{data?.authorized ? "Yes" : "No"}</h5>
                                </div>

                                <div className="mt-4">
                                    <p>Subsequent payment instructions will continue to be delivered in terms of this authority until the obligations in terms of the Agreement have been paid or until this authority is cancelled by me/us by giving you notice in writing:  <h5 className="text-primary">{formatDate(data?.dateSignedAt)}</h5> </p>

                                </div>

                                <div className="mt-4">
                                    <h5>MANDATE</h5>
                                    <p>I/We acknowledge that all payment instructions issued by you shall be treated by my/our abovementioned bank as if the instructions had been issued by me/us personally.</p>
                                </div>

                                <div className="mt-4">
                                    <h5>CANCELLATION</h5>
                                    <p>I/We agree that although this authority and mandate may be cancelled by me/us, such cancellation will not cancel the Agreement. I/We also understand that I/we cannot reclaim amounts, which have been withdrawn from my/our account (paid) in terms of this authority and mandate if such amounts were legally owing to you.</p>
                                </div>

                                <div className="mt-4">
                                    <h5>ASSIGNMENT</h5>
                                    <p>I/We acknowledge that this authority may be ceded or assigned to a third party if the Agreement is also ceded or assigned to that third party.</p>
                                </div>

                                {!data.signatureData ? null : <img src={data.signatureData} alt="Signature" width={600} />}

                                {/* ___________________UNCOMMENT THIS AFTER NCR___________________ */}
                                {/* <LoanFeeBreakdown /> */}

                            </CardBody>
                        </Card>
                    </div>
                    <div className="text-end">
                        <Button className="btn btn-primary text-white" onClick={handleDownload}> {"Download PDF"} </Button>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default MandateDetails