import { Col, Row } from "reactstrap";

const AgreementComponent = ({ borrower }) => {
    return (
        <div className=" border p-4 rounded shadow-sm mt-2">
            {/* Header Section */}
            <Row className="mb-4 text-center">
                <Col md={12}>
                    <h5 className="font-weight-bold text-uppercase">Agreement Acknowledgement</h5>
                </Col>
            </Row>

            {/* Agreement Section */}
            <Row>
                <Col md={12}>
                    <p>
                        I, the undersigned <strong>{borrower?.loan?.user?.firstName} {borrower?.loan?.user?.lastName}</strong> with Identity number <strong>{borrower?.loan?.user?.idNumber}</strong> having accepted the pre-agreement and quotation above hereby acknowledge that:
                    </p>
                    {/* <p>
                        I, the undersigned <strong>{borrower?.loan?.user?.firstName} {borrower?.loan?.user?.lastName}</strong> with Identity number <strong>{borrower?.loan?.user?.idNumber}</strong> (“the Customer”), of address: <strong>{borrower?.loan?.user?.streetAddress}</strong>,
                        my domicile citadel executed for the purposes of any notice in terms of this agreement, having accepted the pre-agreement and quotation above hereby acknowledge that:
                    </p> */}

                    <ol className="pl-4">
                        <li>
                            The essential terms and conditions hereunder have been explained to me and I understand the consequences of the agreement.
                        </li>
                        <li>
                            I have had an opportunity to read the agreement.
                        </li>
                        <li>
                            I have received the <strong>LOAN AMOUNT: R {borrower?.loan?.amount} </strong>.
                        </li>
                        <li>
                            The application completed forms part of these terms and conditions.
                        </li>
                        <li>
                            I have not been induced by the Credit Provider into making this loan and have not signed any blank documents relating to this agreement.
                        </li>
                    </ol>

                    <h5 className="font-weight-bold mt-4">2. Payment Schedule</h5>
                    <p>
                        The payment schedule attached hereto in the form of the pre-agreement statement and quotation (Annexure “A”) sets out the payment information relating to the
                        agreement and must be read as part of this agreement.
                    </p>

                    <h5 className="font-weight-bold mt-4">3. Consent</h5>
                    <p>
                        The Customer consents to the Credit Provider obtaining any information from any credit bureau and/or his/her employer inter alia, without derogating from the generality hereof, details
                        pertaining to the Customer’s credit worthiness, salary, employment, residential address and best contact details. In the event of the Customer being married in community of property,
                        and the Customer’s spouses’ consent is required in respect of this agreement, the Customer warrants that his/her spouses’ requisite consent, if any, will be provided within a reasonable
                        period after a request is made by the Credit Provider for same to be provided. The Customer indemnifies the Credit Provider against any loss or damages it may suffer because of a
                        failure to provide such aforesaid consent.
                    </p>

                    <h5 className="font-weight-bold mt-4">4. Statements</h5>
                    <p>
                        The Credit Provider shall supply the Customer with a copy of the duly executed credit agreement or, in the case of an electronic or telephone originated credit agreement, a copy of the
                        transcribed agreement. The Credit Provider shall provide a statement of account to the Customer on or before the last day of every month for the duration of the credit agreement,
                        which the Customer agrees to. The Customer agrees to collect the statement in person by the Customer at the business premises of the Lender or to be faxed to the Customer at an
                        arranged fax number.
                    </p>

                    <h5 className="font-weight-bold mt-4">5. Marketing</h5>
                    <p>

                        The Customer consents that Credit Provider, its affiliates, associates and subsidiaries may send advertising and promotional material by email, SMS (Short Message System) to his/her
                        computer, cellular telephone as provided herein. The Customer furthermore consents to the Credit Provider sending SMS’s to him/her with regards to his/her outstanding balance(s),
                        when his/her installment is due and payable, when he/she is in arrears with his/her payments and related matters. Should the Customer wish to unsubscribe from such messages, please
                        contact the Credit Provider.
                    </p>

                    <h5 className="font-weight-bold mt-4">  6. Early Settlement</h5>
                    <p>
                        6.1 The Customer is entitled to settle the Agreement at any time, without advance notice to the Credit Provider.
                        6.2 The amount required to settle the Agreement shall be the total of the following amount:
                        6.2.1 the unpaid balance of the principal debt up to the settlement date.
                        6.2.2 the unpaid interest charges up to the settlement date; and
                        6.2.3 All other fees, charges, and insurance payable by the Customer to the Credit Provider up to the settlement date.
                    </p>

                    <h5 className="font-weight-bold mt-4"> 7. Consumer’s right to terminate the agreement </h5>
                    <p>

                        The Customer may terminate this agreement at any time by paying the settlement amount due to the Credit Provider, in accordance with paragraph 6 above.
                    </p>

                    <h5 className="font-weight-bold mt-4"> 8. Credit Provider’s right to terminate the agreement </h5>
                    <p>
                        8.1 The Credit Provider reserves the right, in terms of section 123 of the Act, to terminate this agreement and proceed with legal proceedings in terms of section 129(1)(b) and section
                        130(1) of the Act, which proceedings may result in a Court of law enforcing the repayment of the Customer’s outstanding obligation in terms of this Agreement. Any judgment of
                        such Court, as aforesaid, shall be recorded by the credit bureau available to other credit Providers.
                    </p>

                    <h5 className="font-weight-bold mt-4">  9. Penalty Interest on arrear amounts </h5>
                    <p>
                        9.1 Should the Customer default and / or fails to pay any amount on the due date thereof, then same shall attract penalty interest as calculated in 9.1 below:
                        the Customer shall be liable for and pay interest, calculated on the total amount which is payable but is unpaid at the same rate as set out in the proposed loan agreement and quotation,
                        from the date of default to date of payment in full; and
                        9.2 the full outstanding balance of the capital together with total cost of credit charges (including any unpaid accrued interest at 36.00 percent annually) shall, at the sole discretion
                        and instance of the Credit Provider, immediately become due, owing and payable.
                        9.3 In the case of a large agreement, the Customer may settle the agreement in full at any time, with or without notice, by paying the settlement value owed to the Credit Provider.
                        In such an event, the settlement value will be the amounts referred to in clause 9.2 above, and an early termination charge.
                        9.4 The early termination charge which may be prescribed from time to time, or if no charge has been prescribed, will be a charge equal to the interest that would have been payable
                        under the agreement for a period equal to the difference between 3 (three) months and the period of the notice of settlement if any, that was given by the Customer.
                    </p>

                    <h5 className="font-weight-bold mt-4">  10. Payments </h5>
                    <p>

                        Payments will be allocated firstly towards payment of any due or unpaid interest, thereafter any due or unpaid fees or charges (including legal costs, if any) and lastly capital. I hereby
                        authorize the Credit Provider to debit funds for collection from my account provided in my application form on condition as agreed in this agreement.
                    </p>

                    <h5 className="font-weight-bold mt-4">  11. Breach of Agreement </h5>
                    <p>
                        If the Customer is in default for at least 20 days, subject to 10 days written notice as contemplated in Section 129 (1) or 86(9) / 88(3), the Credit Provider may approach the court for an
                        order to enforce the loan agreement including a claim for all outstanding amounts and / or damages. The Customer agrees to pay all reasonable costs of the collection of payments and
                        further agrees to pay all legal costs on an attorney and client scale caused by his/her default including debt collectors’ costs and tracing fees.
                    </p>

                    <h5 className="font-weight-bold mt-4">    12. Complaints </h5>
                    <p>
                        Complaints may be directed to the Credit Provider at the contact details provided in the Agreement, or to the National Credit Regulator at (011) 554-2600.
                    </p>

                    <h5 className="font-weight-bold mt-4">  13. Cession </h5>
                    <p>
                        The Credit Provider has the right on written notice to the Customer to transfer (cede and assign) all the Credit Provider’s rights and obligations in this agreement to a third party and the
                        Customer will then pay the third party instead of the Credit Provider.
                    </p>

                    <h5 className="font-weight-bold mt-4">  14. Entire Agreement </h5>
                    <p>
                        This agreement constitutes the entire agreement between the parties relative to the subject matter hereof and supersedes all representations, warranties, agreements or undertakings
                        previously made relative to such subject matter, and no such representations, warranties, agreements or undertakings shall be of any force and effect unless contained herein. No
                        indulgence, extension of time, relaxation or latitude which the Credit Provider may show, grant or allow to the Customer shall constitute a waiver by the Credit Provider of any of its
                        rights and the Credit Provider shall not thereby be prejudiced or stopped from exercising any of its rights against the Customer which may have then already arisen or which may
                        thereafter arise, and / or applying / enforcing the terms of this agreement. No variation of any of the terms and conditions of this agreement will be binding on the parties unless
                        committed to writing and signed by them respectively. Should any provision or portion of this Agreement be unenforceable by law, void or voidable, such provision shall be severable
                        from the remaining provisions hereof which shall remain in full force and effect.
                    </p>

                    <h5 className="font-weight-bold mt-4">   15. Credit Life Insurance </h5>
                    <p>

                        It is a requirement that I secure and maintain, for the duration of this Agreement, credit life insurance not exceeding, at any time during the life of this Agreement, the total value of my
                        outstanding obligations to the Lender in terms of this Agreement. I may however voluntarily elect to take out insurance for my own benefit. If I so choose, I can provide the Lender with
                        proof of any applicable Credit Life Insurance provided it has the same cover conditions and will specifically note the lenders claim right in the event of a claim event occurring.
                    </p>

                </Col>
            </Row>
        </div>
    );
};

export default AgreementComponent;
