import LoanFeeBreakdown from "components/common/LoanFeeBreakdown";
import { useStore1Selector } from "index";
import { Col, Row } from "reactstrap";
import LoadingAnimation from "../../../components/helper/Loading-animation";
import { ServerError } from "../../../constant/NotifyMessage";
import useFetch from '../../../hooks/useFetch';
import { loginUser } from "../../../Redux/Slices/userSlice";
import { formatDate } from "../../../utils/dateFormat";
import AgreementComponent from "./AgrrementTerms";


const PreAgreementDetails = ({ id }) => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates/${id}`, token);

    const calculateInitiationFee = (loanAmount) => {
        if (loanAmount <= 1000) {
            return loanAmount * 0.15;
        } else {
            const first1000Fee = 1000 * 0.15;
            const remainingAmountFee = (loanAmount - 1000) * 0.10;
            return first1000Fee + remainingAmountFee;
        }
    };

    const calculateServiceFee = (numberOfDays) => {
        return 2 * numberOfDays;
    };

    const calculateInterest = (loanAmount, numberOfDays) => {
        const interestRatePerDay = 0.001;
        return loanAmount * interestRatePerDay * numberOfDays;
    };

    const loanAmount = data?.loan?.amount || 0;
    const numberOfDays = data?.loan?.duration || 0;

    const initiationFee = calculateInitiationFee(loanAmount);
    const serviceFee = calculateServiceFee(numberOfDays);
    const interestFee = calculateInterest(loanAmount, numberOfDays);
    const totalToPay = initiationFee + serviceFee + interestFee + loanAmount;

    if (loading) return <LoadingAnimation />

    if (error) return <ErrorPage message={ServerError} />

    return (
        <div id="loanContentToDownload">

            <Row>
                <Col>
                    <h1 className="text-center">PRE AGREEMENT FORM</h1>
                </Col>
            </Row>

            {/* HEADER SECTION */}
            <div className="container border p-4 rounded shadow-sm mb-2">
                <Row className="mb-4 text-center">
                    <Col md={12}>
                        <h3 className="text-uppercase font-weight-bold">RITIKE FINANCE (Pty) Ltd</h3>
                        <h5 className="text-muted">RITIKE FINANCE (Pty) Ltd | 2020/579311/07</h5>
                        <h6>Soshanguve East - Ext 5</h6>
                        <h6>0713171008 / info@ritikefinance.com</h6>
                        <h6 className="font-italic">Regulator no NCRCP14340</h6>
                    </Col>
                </Row>

                {/* Customer and Bank Details */}
                <Row className="border-top pt-4">
                    {/* Customer Details */}
                    <Col md={6} className="border-right">
                        <h5 className="text-uppercase font-weight-bold mb-3">Customer Details</h5>
                        <ul className="list-unstyled">
                            <li><strong>Name:</strong> {data?.loan?.user?.firstName} {data?.user?.lastName}</li>
                            <li><strong>ID Number:</strong> {data?.loan?.user?.idNumber || "N/A"}</li>
                            <li><strong>Address:</strong> {data?.user?.streetAddress || "N/A"}</li>
                            <li><strong>Employer:</strong> {data?.user?.companyName || "N/A"}</li>
                        </ul>
                    </Col>
                    {/* Customer Bank Details */}
                    <Col md={6}>
                        <h5 className="text-uppercase font-weight-bold mb-3">Customer Bank Details</h5>
                        <ul className="list-unstyled">
                            <li><strong>Bank Name:</strong> {data?.user?.bankName || "N/A"}</li>
                            <li><strong>Branch Code:</strong> {data?.user?.branchCode || "N/A"}</li>
                            <li><strong>Account No:</strong> {data?.user?.accountNumber || "N/A"}</li>
                            <li><strong>Account Type:</strong> {data?.user?.accountType || "N/A"}</li>
                        </ul>
                    </Col>
                </Row>
            </div>

            {/* _______________UNCOMMENT THIS PART ONCE YOU ARE DONE WITH NCR_______________ */}
            {/* <LoanFeeBreakdown /> */}

            {/* User and Loan Details Block */}
            <div className="container border p-4 rounded shadow-sm mt-2">
                <Row className="mb-4 text-center">
                    <Col md={12}>
                        <h5 className="font-weight-bold text-uppercase">User & Loan Details</h5>
                    </Col>
                </Row>

                <Row>
                    {/* Full Name */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Full Name</h6>
                        <p>{`${data?.user?.firstName || 'N/A'} ${data?.user?.lastName || 'N/A'}`}</p>
                    </Col>

                    {/* Phone Number */}
                    {/* <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Phone Number</h6>
                        <p>{data?.user?.phoneNumber || 'N/A'}</p>
                    </Col> */}

                    {/* ID Number */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">ID Number</h6>
                        <p>{data?.loan?.user?.idNumber || 'N/A'}</p>
                    </Col>

                    {/* Email */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Email</h6>
                        <p>{data?.user?.email || 'N/A'}</p>
                    </Col>

                    {/* Account Number */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Account Number</h6>
                        <p>{data?.user?.accountNumber || 'N/A'}</p>
                    </Col>

                    {/* Bank Name */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Bank Name</h6>
                        <p>{data?.user?.bankName || 'N/A'}</p>
                    </Col>

                    {/* Payment Date */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Payment Date</h6>
                        <p>{formatDate(data?.loan?.paymentDate)}</p>
                    </Col>

                    {/* Loan Amount */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Loan Amount</h6>
                        <p>{`R ${data?.loan?.amount || 'N/A'}`}</p>
                    </Col>

                    {/* Breakdown of Fees */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Initiation Fee</h6>
                        <p>{`R ${initiationFee.toFixed(2)}`}</p>
                    </Col>

                    <Col md={4} className="mb-3">
                        {/* <h6 className="font-weight-bold">Service Fee (R2/day)</h6> */}
                        <h6 className="font-weight-bold">Service Fee </h6>
                        <p>{`R ${serviceFee.toFixed(2)}`}</p>
                    </Col>

                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Interest Fee</h6>
                        <p>{`R ${interestFee.toFixed(2)}`}</p>
                    </Col>

                    {/* Total Amount to Pay */}
                    <Col md={4} className="mb-3">
                        <h6 className="font-weight-bold">Total Amount to Pay</h6>
                        <p>{`R ${totalToPay.toFixed(2)}`}</p>
                    </Col>

                    {/* Optional Fields */}
                    {
                        data?.gracePeriodAmount && (
                            <Col md={4} className="mb-3">
                                <h6 className="font-weight-bold">Grace Period Amount</h6>
                                <p>{data?.gracePeriodAmount}</p>
                            </Col>
                        )
                    }

                    {
                        data?.gracePeriodPercentage && (
                            <Col md={4} className="mb-3">
                                <h6 className="font-weight-bold">Grace Period Percentage</h6>
                                <p>{data?.gracePeriodPercentage}</p>
                            </Col>
                        )
                    }

                    {
                        data?.gracePeriodPaymentDate && (
                            <Col md={4} className="mb-3">
                                <h6 className="font-weight-bold">Grace Period Payment Date</h6>
                                <p>{formatDate(data?.gracePeriodPaymentDate)}</p>
                            </Col>
                        )
                    }
                </Row>
            </div>

            {/* Signature Section Block */}
            <div className="container border p-4 rounded shadow-sm mt-2">
                <Row className="mb-4 text-center">
                    <Col md={12}>
                        <h5 className="font-weight-bold text-uppercase">Signatures</h5>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} className="text-center">
                        <h6 className="font-weight-bold mb-3">Borrower</h6>
                        <div className="border rounded p-3 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <div>
                                <img src={data?.signatureData}
                                    alt="BSignature of Borrower"
                                    style={{
                                        objectFit: "contain",
                                        maxWidth: "40%",
                                    }}
                                />
                                <p className="pb-4"><strong>Date Signed: </strong>{formatDate(data?.dateSignedAt) || 'N/A'}</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={6} className="text-center">
                        <h6 className="font-weight-bold mb-3">Credit Provider</h6>
                        <div className="border rounded p-3 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <div>
                                <p className="mb-4"><strong>RUDZAI</strong></p>
                                <p><strong>Date Signed: </strong>{formatDate(data?.updatedAt) || 'N/A'}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <AgreementComponent borrower={data} />

            <div className="container border p-4 rounded shadow-sm mt-2">
                <Row className="mb-4 text-center">
                    <Col md={12}>
                        <h5 className="font-weight-bold text-uppercase">Signature and Date</h5>
                    </Col>
                </Row>

                <Row>

                    <Col md={6} className="text-center mb-3">
                        <h6 className="font-weight-bold mb-3">Signature of Borrower</h6>
                        <div className="border rounded p-3 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <div>
                                <img src={data?.signatureData}
                                    alt="BSignature of Borrower"
                                    style={{
                                        objectFit: "contain",
                                        maxWidth: "40%",
                                    }}
                                />
                                <p className="pb-4"><strong>Date Signed: </strong>{formatDate(data?.dateSignedAt) || 'N/A'}</p>
                            </div>
                        </div>
                    </Col>

                    <Col md={6} className="text-center mb-3">
                        <h6 className="font-weight-bold mb-3">Signature of Credit Supplier or Authorized Representative</h6>
                        <div className="border rounded p-3 d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                            <div>
                                <p className="mb-4"><strong>RUDZAI</strong></p>
                                <p><strong>Date Signed: </strong>{formatDate(data?.updatedAt) || 'N/A'}</p>
                            </div>
                        </div>
                    </Col>


                </Row>
            </div>

        </div>
    )
}
export default PreAgreementDetails